<template>
  <div class="container">
    <div class="section-title">
      <h2>Project</h2>
    </div>

    <div class="row" data-aos="fade-up">
      <div class="col-lg-12 d-flex justify-content-center">
        <ul id="portfolio-flters">
          <li data-filter="*" class="filter-active">{{ all }}</li>
          <li data-filter=".filter-html-css">{{ htmlCss }}</li>
        </ul>
      </div>
    </div>

    <div
      class="row portfolio-container"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div
        v-for="(project, index) in projects"
        :key="index"
        class="col-lg-4 col-md-6 portfolio-item"
        :class="project.projectType"
      >
        <div class="portfolio-wrap">
          <img :src="project.image" class="img-fluid" alt="Project Image" />
          <div class="portfolio-links">
            <a target="_blank" :href="project.link" title="Visit Project"
              ><i class="bx bx-link"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  data() {
    return {
      projects: [
        {
          image: "assets/img/portfolio/pickaboo.png",
          link: "http://pickaboo.epizy.com",
          projectType: "filter-html-css",
        },
        {
          image: "assets/img/portfolio/microsoft.png",
          link: "http://microsoft-clone.epizy.com",
          projectType: "filter-html-css",
        },
        {
          image: "assets/img/portfolio/onu.png",
          link: "http://onu-psd.epizy.com",
          projectType: "filter-html-css",
        },
        {
          image: "assets/img/portfolio/tinyone.png",
          link: "http://tinyone-psd.epizy.com",
          projectType: "filter-html-css",
        },
        {
          image: "assets/img/portfolio/consi.png",
          link: "http://conseisi.epizy.com",
          projectType: "filter-html-css",
        },
        {
          image: "assets/img/portfolio/consii.png",
          link: "http://conseisii.epizy.com",
          projectType: "filter-html-css",
        },
        {
          image: "assets/img/portfolio/consiii.png",
          link: "http://conseisiii.epizy.com",
          projectType: "filter-html-css",
        },
      ],
      all: "All",
      htmlCss: "HTML | CSS | JS",
    };
  },
};
</script>

<style scoped>
.project-title {
  display: none;
}
.portfolio-wrap:hover .project-title {
  display: block;
  transition: all ease-in-out 0.3s;
  left: 0;
  right: 0;
  top: 0;
  z-index: 6;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>