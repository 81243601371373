<template>
  <home></home>
</template>

<script>
import Home from "./pages/Home";
export default {
  components: { Home },
};
</script>


