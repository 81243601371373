<template>
  <div class="container">
    <div class="section-title">
      <h2>About</h2>
      <p>{{ about }}</p>
    </div>

    <div class="row">
      <div class="col-lg-4" data-aos="fade-right">
        <img src="assets/img/cover.jpeg" class="img-fluid" alt="Cover Photo" />
      </div>
      <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
        <h3>{{ designation }}</h3>

        <div class="row mt-5">
          <div class="col-lg-6">
            <ul>
              <li>
                <i class="bi bi-chevron-right"></i>
                <strong>Date of Birth:</strong> <span>{{ dob }}</span>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <strong>Gender:</strong>
                <span>{{ gender }}</span>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <strong>Mobile:</strong>
                <span>{{ mobile }}</span>
              </li>
            </ul>
          </div>
          <div class="col-lg-6">
            <ul>
              <li>
                <i class="bi bi-chevron-right"></i>
                <strong>Degree:</strong> <span>{{ degree }}</span>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <strong>Email:</strong>
                <span>{{ email }}</span>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i> <strong>City:</strong>
                <span>{{ city }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      about: "I'm Md. Rakib Hasan from Helsinki, Finland. I completed my Bachelor of Business Administration (BBA) from Asian University of Bangladesh in 2018. I've been familiar with HTML, CSS, and JavaScript for over 3 years. My dream is to become a Full Stack Web Developer, and I'm working tirelessly to make my dream come true. I'm interested in learning React and Node.js to become a full stack web developer. Currently, I'm searching for a place to learn React and Node.js to enhance my skills and advance my career.",
      designation: "Web Developer",
      dob: "1st January 1995",
      mobile: "+358 - 417921348",
      email: "rhasanbd.786@gmail.com",
      city: "Helsinki, Finland",
      degree: "BBA",
      gender: "Male",
    };
  },
};
</script>

<style>
</style>