var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row",attrs:{"data-aos":"fade-in"}},[_vm._m(1),_c('div',{staticClass:"col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch"},[_c('form',{staticClass:"php-email-form",attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control",attrs:{"type":"text","name":"name","id":"name","placeholder":"Enter Your Name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"form-group col-md-6"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",attrs:{"type":"email","name":"email","id":"email","placeholder":"Enter Your Email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})])]),_c('div',{staticClass:"form-group"},[_vm._m(4),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"form-control",attrs:{"name":"message","rows":"6","placeholder":"You Message"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}})]),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('h2',[_vm._v("Contact")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-5 d-flex align-items-stretch"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"address"},[_c('i',{staticClass:"bi bi-geo-alt"}),_c('h4',[_vm._v("Location")]),_c('p',[_vm._v("Helsinki, Finland")])]),_c('div',{staticClass:"email"},[_c('i',{staticClass:"bi bi-envelope"}),_c('h4',[_vm._v("Email")]),_c('p',[_vm._v("rhasanbd.786@gmail.com")])]),_c('div',{staticClass:"phone"},[_c('i',{staticClass:"bi bi-phone"}),_c('h4',[_vm._v("Mobile")]),_c('p',[_vm._v("+358 - 417921348")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_vm._v("Name "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"email"}},[_vm._v("Email "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_vm._v("Message "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{attrs:{"type":"submit"}},[_vm._v("Send")])])
}]

export { render, staticRenderFns }