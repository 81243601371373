<template>
  <div>
    <a
      v-for="(link, index) in links"
      :key="index"
      :href="link.url"
      target="_blank"
      ><i :class="link.class"></i
    ></a>
  </div>
</template>

<script>
export default {
  name: "SocialIcon",
  data() {
    return {
      links: [
        { url: "https://github.com/rakibhasan95", class: "bx bxl-github" },
        {
          url: "https://www.facebook.com/rakibhasan.rony.18",
          class: "bx bxl-facebook",
        },
        {
          url: "https://www.linkedin.com/in/md-rakib-hasan-9448b6183",
          class: "bx bxl-linkedin",
        },
      ],
    };
  },
};
</script>

<style>
</style>