var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row skills-content"},_vm._l((_vm.skillsGroups),function(group,i){return _c('div',{key:i,staticClass:"col-lg-6",attrs:{"data-aos":"fade-up"}},_vm._l((_vm.skills.slice(
          i * _vm.itemsPerRow,
          (i + 1) * _vm.itemsPerRow
        )),function(skill,index){return _c('div',{key:index,staticClass:"progress"},[_c('span',{staticClass:"skill"},[_vm._v(_vm._s(skill.lang)+" "),_c('i',{staticClass:"val"},[_vm._v(_vm._s(skill.percent)+"%")])]),_c('div',{staticClass:"progress-bar-wrap",style:({
            background: skill.color,
            width: skill.percent + '%',
          })})])}),0)}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('h2',[_vm._v("Skills")])])
}]

export { render, staticRenderFns }