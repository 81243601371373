<template>
  <div class="container">
    <div class="section-title">
      <h2>Resume</h2>
    </div>

    <div class="row">
      <div class="col-lg-6" data-aos="fade-up">
        <h3 class="resume-title">Education</h3>
        <div class="resume-item">
          <h4>Bachelor of Business Administration (BBA)</h4>
          <h5>2018</h5>
          <p><em>Asian University of Bangladesh</em></p>
        </div>
        <div class="resume-item">
          <h4>Higher Secondary Certificate (HSC) in Business Studies</h4>
          <h5>2014</h5>
          <p><em>Gridakalindia Hazera Hasmat Degree College</em></p>
        </div>
        <div class="resume-item">
          <h4>Secondary School Certificate (SSC) in Business Studies</h4>
          <h5>2010</h5>
          <p>
            <em>Charpata Ishakia Moitry High School, Raipur, Lakshmipur</em>
          </p>
        </div>
      </div>
      <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
        <h3 class="resume-title">Professional Experience</h3>
        <div class="resume-item">
          <h4>Web Designer</h4>
          <h5>January, 2019 - December, 2019 (1 year)</h5>
          <p><em>Techno Outsource, Dhaka, Bangladesh</em></p>
          <ul>
            <li>Design, development and implementation of Web Application</li>
          </ul>
        </div>
        <div class="resume-item">
          <h4>Jr. Software Engineer (Frontend)</h4>
          <h5>January, 2020 - December, 2021 (2 years)</h5>
          <p><em>Bindu IT Solutions, Dhaka, Bangladesh</em></p>
          <ul>
            <li>Design, development and implementation of Web Application</li>
            <li>Implement and Integret API for Web Application</li>
          </ul>
        </div>

        <h3 class="resume-title">Training Experience</h3>
        <div class="resume-item">
          <h4>Web Design and Development</h4>
          <h5>2019</h5>
          <p>
            <em>BASIS Institute of Technology &amp; Management (BITM)</em>
          </p>
        </div>
        <div class="resume-item">
          <h4>Advanced Certificate Course in Computer Application</h4>
          <h5>2018</h5>
          <p><em>National Institue of Technology, Bangladesh</em></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Resume",
  data() {
    return {};
  },
};
</script>

<style>
</style>